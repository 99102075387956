mapboxgl.accessToken = 'pk.eyJ1IjoiZHdueiIsImEiOiJjamM3OWNibmkxcHM1MndqZG1peGRoaXBtIn0.lGkgRJ55UTVTVyAOYBX7hw';

import graph from "./graph.js";

export default {
    run: (track, steps, points, features) => {
        let interactive = window.location.hash.indexOf("build") !== -1;

        graph.setup(steps);
        // return;

        let map = new mapboxgl.Map({
            container: 'map',
            zoom: features[0].Zoom,
            center: [features[0].Longitude, features[0].Latitude],
            pitch: features[0].Pitch,
            bearing: features[0].Bearing,
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            interactive: window.location.hash.indexOf("build") !== -1
        });

        if (window.location.hash.indexOf("build") !== -1) {
            map.on('moveend', () => {
                console.log("------------");
                console.log("TRACK", track.Id);
                console.log("ZOOM", map.getZoom());
                console.log("PITCH", map.getPitch());
                console.log("BEARING", map.getBearing());
                console.log("CENTER", map.getCenter());
                console.log("------------");
            });
        }

        map.on('load', () => {
            $('#map').addClass('map--in');

            // Add some fog in the background
            map.setFog({
                'range': [2, 12],
                'color': '#D5E6F3',
                'horizon-blend': 0.1
            });

// Add a sky layer over the horizon
            map.addLayer({
                'id': 'sky',
                'type': 'sky',
                'paint': {
                    'sky-type': 'atmosphere',
                    'sky-atmosphere-color': 'rgba(85, 151, 210, 0.5)'
                }
            });

// Add terrain source, with slight exaggeration
            map.addSource('mapbox-dem', {
                'type': 'raster-dem',
                'url': 'mapbox://mapbox.terrain-rgb',
                'tileSize': 512,
                'maxzoom': 14
            });

            map.setTerrain({'source': 'mapbox-dem', 'exaggeration': 1.5});

            let coords = [];

            steps.forEach(i => {
                coords.push([i.lon, i.lat])
            });

            map.addSource('route', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coords
                    }
                }
            });
            map.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                'paint': {
                    'line-color': '#79b532',
                    'line-width': 8
                }
            });

            points.forEach(i => {
                const el = document.createElement('div');
                el.innerHTML = "<div><div class='map--marker'>" + i.Note + "</div></div>";
                const element = el.firstChild;

                const marker = new mapboxgl.Marker({
                    element: element
                })
                    .setLngLat([i.Longitude, i.Latitude])
                    .addTo(map);
            });

            let stepIndex = 0;

            function runStep() {
                map.flyTo({
                    zoom: features[stepIndex].Zoom,
                    center: [features[stepIndex].Longitude, features[stepIndex].Latitude],
                    pitch: features[stepIndex].Pitch,
                    bearing: features[stepIndex].Bearing,
                    duration: features[stepIndex].Duration
                });

                if (stepIndex < features.length - 1) {
                    setTimeout(runStep, features[stepIndex].Duration + 500);
                } else {
                    setTimeout(() => {
                        map.flyTo({
                            zoom: features[0].Zoom,
                            center: [features[0].Longitude, features[0].Latitude],
                            pitch: features[0].Pitch,
                            bearing: features[0].Bearing
                        });

                        setTimeout(() => {
                            stepIndex = 0;
                            runStep();
                        }, 3000);

                    }, features[stepIndex].Duration + 3000);
                }

                stepIndex++;
            }

            if (!interactive) {
                runStep();
            }
        });
    }
}
