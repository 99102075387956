export default {
    setup(steps) {
        let elevationData = [];
        let orderedElevationData = [];
        let elevationLabels = [];

        steps.forEach(x => {
            elevationData.push(Math.floor(x.elevation))
            orderedElevationData.push(Math.floor(x.elevation))
            elevationLabels.push("Label");
        });

        orderedElevationData.sort((a, b) => {
            return a - b;
        });

        this.lowestPoint = orderedElevationData[0];
        this.highestPoint = orderedElevationData[orderedElevationData.length - 1];

        let ctx = document.getElementById('elevationChart').getContext('2d');
        let elevationChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: elevationLabels,
                datasets: [{
                    label: "Elevation",
                    data: elevationData,
                    fill: 'start',
                    backgroundColor: '#79b532',
                }]
            },
            options: {

                scales: {
                    y: {
                        beginAtZero: true,
                        min: 0,
                        label: (context) => {
                            return context.raw + "m";
                        }
                    },
                    x: {
                        display: false
                    },
                },
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            title: () => {
                                return "Elevation";
                            },
                            label: (context) => {
                                return context.raw + "m";
                            }
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 0
                    },
                    line: {
                        tension: 0.4
                    }
                }
            }
        });
    }
}